import React, { FC } from 'react';
import { TagProps } from '../../atoms/Tag';
import { SearchFieldProps } from '../../atoms/SearchField';
import * as SC from './styled';
export type SearchBlockProps = MinimalSeoBlock & {
  className?: string;
  title?: string;
  tags?: TagProps[];
  searchProps?: SearchFieldProps;
};
const SearchBlock: FC<SearchBlockProps> = props => {
  const {
    className,
    htmlTag,
    title,
    htmlTitleTag,
    tags,
    searchProps
  } = props;
  return <SC.Container className={className} as={htmlTag}>
      {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
      {searchProps && <SC.Field {...searchProps} />}
      {tags && <SC.Tags tags={tags} />}
    </SC.Container>;
};
export default SearchBlock;