import React, { FC } from 'react';
import { LinkProps } from '../Link';
import * as SC from './styled';
import { TagVariant } from './styled';
export type TagProps = LinkProps & {
  className?: string;
  label?: string;
  value?: string;
  active?: boolean;
  variant?: TagVariant;
};
const Tag: FC<TagProps> = ({
  label,
  active,
  className,
  variant = TagVariant.cloud,
  ...linkProps
}) => {
  return <SC.Tag {...linkProps} className={className} $active={active} $variant={variant}>
      {label}
    </SC.Tag>;
};
export default Tag;