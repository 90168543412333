import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { transformTagForCloud } from 'src/relay/Common/tag';
import { RecipesSearchEngineBlock as Data, SearchEngineType } from '../../../graphql/generated/api-graphql';
import SearchBlock, { SearchBlockProps } from '../../../components/molecules/SearchBlock';
import { FormFieldAutoSuggestValue } from '../../../components/form/fields/FormFieldAutoSuggest';
import { actions, selectors } from '../../../redux';
import { suggestionTransformer } from '../../SearchEngine/searchSuggestion';
import { BlockProps } from '../props';
import Router, { routes } from '../../../routes/Router';
import { SearchType } from '../../SearchEngine/searchType';
export type RecipesSearchEngineBlockProps = Omit<BlockProps, 'data'> & {
  data: Data;
};
const RecipesSearchEngineBlock: FC<RecipesSearchEngineBlockProps> = ({
  data,
  template
}) => {
  const {
    t
  } = useTranslation();
  const [value, setValue] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    data: suggestions
  } = useSelector(selectors.search.autocomplete);
  const request = useMemo(() => debounce((q: string) => dispatch(actions.search.autocompleteRequest({
    q,
    type: SearchEngineType.Recipe
  })), 200), [dispatch]);
  useEffect(() => {
    if (value?.length > 0 && !submitted) {
      request(value);
    } else {
      dispatch(actions.search.autocompleteReset());
    }
  }, [value, request, dispatch, submitted]);
  const props: SearchBlockProps = {
    title: data?.title ?? '',
    htmlTitleTag: 'p',
    searchProps: {
      name: 'search',
      placeholder: t('search_placeholder'),
      'aria-label': t('search_aria_label'),
      value,
      suggestions: suggestions?.map(suggestionTransformer),
      onChange: e => setValue(e.target.value),
      onSubmit: (query?: string | FormFieldAutoSuggestValue) => {
        setSubmitted(true);
        if ((query as FormFieldAutoSuggestValue)?.value) {
          dispatch(actions.search.setSearchDataLayer({
            search_bar_location: template,
            search_type: 'suggestion'
          }));
          Router.push((query as FormFieldAutoSuggestValue).value ?? '');
        } else {
          dispatch(actions.search.setSearchDataLayer({
            search_bar_location: template,
            search_type: 'direct'
          }));
          Router.push(Router.getRouteUrl(routes.search, {
            q: query,
            type: SearchType.Recipe
          }));
        }
      }
    },
    tags: data.tags?.map(tag => transformTagForCloud(tag, template, true, dispatch))
  };
  return <SearchBlock {...props} />;
};
export default RecipesSearchEngineBlock;